'use strict';

window.$ = window.jQuery = $;

history.pushState('', document.title, '/florist-choice-plus/' + $('#slug').val());

// set thisGlobal to clicked product-card
var thisGlobal;
const sizes = {'premium': 2, 'deluxe': 1, 'standard': 0};

$(document).ready(function () {
  // $(window).on('popstate', function() {
  //   if (window.location.hash !== '#sidebar') {
  //     hideSidebar();
  //   }
  // });

  $(window).scroll(function () {
    var height = $(document).scrollTop();
    var width = $(window).width();
    var margin = width < 768 ? '-111px' : '-86px';
    var heightCheck = width < 768 ? 110 : 55;
    if (height > heightCheck) {
      $('.header__inner-wrapper').css('margin-top', margin);
      $('#arrow').css('top', '8px');
      $('#arrow').css('color', 'white');
      $('.continue-text').css('color', 'white');
      $('.continue-text').css('top', '8px');
      $('.continue-text').css('position', 'relative');
      //if mobile device hide .continue-text
      if (width < 768) {
        $('.continue-text').css('visibility', 'hidden');
      }



    } else {
      $('.header__inner-wrapper').css('margin-top', '0px');
      $('#arrow').css('top', '0px');
      $('#arrow').css('color', ''); 
      $('.continue-text').css('color', '');
      $('.continue-text').css('top', '0px');
      $('.continue-text').css('visibility', 'visible');
    }
  });

  $('.flyout__product-sizes__standard, .flyout__product-sizes__deluxe, .flyout__product-sizes__premium').on('click', function (e) {
    $('.flyout__product-sizes__standard, .flyout__product-sizes__deluxe, .flyout__product-sizes__premium').removeClass('selected');
    $(this).addClass('selected');

    let installmentPrice = (parseFloat($(this).find('.flyout__product-sizes__price').text().replace(/[$C]/g, '')) / 4).toFixed(2);
    let klarnaInstallmentsContainer = $('.klarna-installment-price');
    klarnaInstallmentsContainer.html(' $' + installmentPrice);

    var size = $(this).find('.flyout__product-sizes__size').text().toLowerCase();
    $('#productSize').val(size);

    var filename = $(this).find('.flyout__product-sizes__image-filename').text();
    if (filename !== '') {
      $('.flyout__product-info__product-image').attr('src',
          'https://res.cloudinary.com/ufn/image/upload/c_pad,f_auto,fl_progressive,q_auto/' + filename); 
    }

    updateSkuAndDimensions(size);
  });

  $('.sidebar-toggle').click(function (e) {
    thisGlobal = this;

    if ($('#sidebar-background').css('background') !== 'none') {

      $('.flyout__inner').scrollTop(0);

      populateProductInfo(this);
      if ($(this).find('.product__product-info__is_gndl').text() === '1') {
        $('.virtual').show();
        $('.not-virtual').hide();
      } else {
        $('.virtual').hide();
        $('.not-virtual').show();
      }


      $('.flyout__inner').css('transform', 'none');
      // if user is on mobile device set body to not scrollable
      if ($(window).width() < 768) {
        $('body').css('overflow', 'hidden');
      }
      $('#sidebar-background').css({
        'visibility': 'visible', 'background': 'rgba(0, 0, 0, .4)'
      });
      $('#sidebar').css({
        'visibility': 'visible'
      });
      window.location.hash = '#sidebar';
    } else {
      hideSidebar();
      $('.virtual').hide();
      $('.not-virtual').show();
    }
  });

  $('body').click(function(e) {
    if (e.target.id !== 'sidebar' || $('#sidebar-background').css('background') === 'none') {
      return;
    }
    hideSidebar();
  });

  $('.flyout__back-button').click(function (e) {
    console.log('clicked');
    hideSidebar();
  });

  $('#free-delivery-text').click(function (e) { 
    hideSidebar();
  });


  $(document).on('keydown', function (e) {
    if ((e.key === 'Escape' || e.key === 'Backspace') && $('#sidebar-background').css('transform') === 'none') {
      hideSidebar();
    }
  });

  var activeModal = '';

  $('[data-reveal-id]')
    .click(function () {
      var modalId = $(this)
        .data('reveal-id');

      if (activeModal === modalId) {
        return;
      }

      activeModal = modalId;

      if ($('.reveal-modal-bg')
        .length === 0) {
        $('<div class="reveal-modal-bg" />')
          .appendTo('body');
        $('.reveal-modal-bg')
          .click(closeAll);
      }

      setTimeout(function () {
        $('#' + modalId)
          .removeClass('slideOutUp')
          .addClass('slideInDown');
      }, 200);

      setTimeout(() => {
        window.lazyLoader.revalidate();
      }, 1000);

    });

  $(document)
    .keyup(function (e) {
      if (e.key === "Escape") {
        closeAll();
      }
    });

  $('.close-reveal-modal, .reveal-modal-bg')
    .click(closeAll);

    $('.flyout_disable')
    .click(closeAll);

  function closeAll() {
    $('#' + activeModal)
      .removeClass('slideInDown')
      .addClass('slideOutUp');
    $('.reveal-modal-bg')
      .removeClass('is-active');
    activeModal = '';
  }

  //check if .delivery-later-modal has class of .slideInDown and if make .flyout_disable visible
  setInterval(function () {
    if ($('.delivery-later-modal').hasClass('slideInDown')) {
      $('.flyout_disable').css('visibility', 'visible');
    } else {
      $('.flyout_disable').css('visibility', 'hidden');
    }

    if($('#free-delivery-disclaimer').hasClass('slideInDown')) {
      hideSidebar(true);
    }

  }, 100);


  


      

  function hideSidebar(freedelivery) {
    $('.flyout__inner').css({
      'transform': 'translate(100%, 0%)'
    });
    $('#sidebar-background').css({
      'visibility': 'hidden', 'background': 'none'
    });
    // set body to scrollable again
    $('body').css('overflow', 'auto');
    $('#sidebar').css({
      'visibility': 'hidden'
    });
    
    if(!freedelivery){
      closeAll();
    
      $('.reveal-modal-bg').removeClass('is-active')
    }
    

    history.pushState('', document.title, window.location.pathname);
    setTimeout(() => destroyProductInfo(), 300);
  }

  function populateProductInfo(t) {
    // Hidden inputs for form data
    $('#accountProductId').val($(t).find('.product__product-info__account-product-id').text());

    $('.flyout__product-info__product-name').text($(t).find('.product__product-info__name-heading').text());

    for (var key in sizes) {
      if ($(t).find('.product__product-info__variations-' + sizes[key] + '-is-active').text() === '0') {

        $('.flyout__product-sizes__' + key).hide();

      } else {
        
        $('.flyout__product-sizes__' + key).show();
        $('.flyout__product-sizes__' + key).children().eq(0).text($(t).find('.product__product-info__variations-' + sizes[key] + '-size').text());
        $('.flyout__product-sizes__' + key).children().eq(1).text('$' + parseFloat($(t).find('.product__product-info__variations-' + sizes[key] + '-price').text()).toFixed(2));
        $('.flyout__product-sizes__' + key).children().eq(2).text($(t).find('.product__product-info__variations-' + sizes[key] + '-image-filename').text());
      
      }
    }

    var selected = $(t).find('.product__product-info__variations-selected').text();
    
    if (selected) {
      $('.flyout__product-sizes__' + selected).click();
    } else {
      $('.flyout__product-sizes__premium').click();
    }
    
    var filename = $('.flyout__product-sizes').find('.selected').children().eq(2).text();
    if (filename !== '') {
      $('.flyout__product-info__product-image').attr('src',
        'https://res.cloudinary.com/ufn/image/upload/c_pad,f_auto,fl_progressive,q_auto/' + filename);      
    } else {
      $('.flyout__product-info__product-image').attr('src', $(t).find('img').attr('src'));
    }

    $('.flyout__description__text').text($(t).find('.product__product-info__description').text());


    if ($(t).find('.product__product-info__is_21plus').text() === '1') {
      $('.alcohol-disclaimer').css({'width': '100%', 'height': '95px'});
      $('.alcohol-disclaimer').children().eq(1).css({'line-height': '1.2', 'margin-bottom': '0px'});
      $('.alcohol-disclaimer').show();
    } else {
      $('.alcohol-disclaimer').hide();
    }


    setSkuAndDimensions(t);
  }

  function setSkuAndDimensions(t) {
    // Check if deluxe variation has unique sku or dimensions, if not default to standard values
    var sku = '';
    var dimensions = '';

    if ($(t).find('.product__product-info__dimensions-1').text() !== '') {
      dimensions = $(t).find('.product__product-info__dimensions-1').text();
    } else if ($(t).find('.product__product-info__dimensions-0').text() !== '') {
      dimensions = $(t).find('.product__product-info__dimensions-0').text();
    }

    if ($(t).find('.product__product-info__sku-1').text() !== '') {
      sku = $(t).find('.product__product-info__sku-1').text();
    } else if ($(t).find('.product__product-info__sku-0').text() !== '') {
      sku = $(t).find('.product__product-info__sku-0').text();
    }

    $('.flyout__product-dimensions').text(dimensions);
    $('.flyout__product-sku').text('Product ID ' + sku);
  }

  function updateSkuAndDimensions(size) {
    var t = thisGlobal;
    // When new size is clicked check if unique sku or dimensions exist and replace values if so

    if ($(t).find('.product__product-info__dimensions-' + sizes[size]).text() !== '') {
      $('.flyout__product-dimensions').text($(t).find('.product__product-info__dimensions-' + sizes[size]).text());
    }
    if ($(t).find('.product__product-info__sku-' + sizes[size]).text() !== '') {
      $('.flyout__product-sku').text('Product ID ' + $(t).find('.product__product-info__sku-' + sizes[size]).text());
    }
  }

  function destroyProductInfo() {
    $('.flyout__product-info__product-name').text('');
    $('.flyout__product-info__product-image').attr('src', '');

    for (var key in sizes) {
      $('.flyout__product-sizes__' + key).children().eq(0).text('');
      $('.flyout__product-sizes__' + key).children().eq(1).text('');
      $('.flyout__product-sizes__' + key).children().eq(2).text('');
    }

    $('.flyout__description__text').text('');
    $('.flyout__product-sku').text('');
    $('.flyout__product-dimensions').text('');
    $('.alcohol-disclaimer').hide();
  }
});