import lyReadMore from 'read-more';

(function ($, lyReadMore) {

  var pageSize = 2;

  var reviewsSectionElem = $('#reviews-section');
  var summaryElem = reviewsSectionElem.find('#reviews-section-summary');

  var carouselListElem = reviewsSectionElem.find('#reviews-carousel-list-container');
  var carouselControlsElem = reviewsSectionElem.find('#reviews-carousel-controls-container');

  var carouselButtonClass = 'homepage-reviews__carousel__page-controls__button';
  var carouselButtonActiveClass = carouselButtonClass + '--active';

  var reviewsData;
  var fcplus = window.location.pathname.includes('florist-choice-plus');

  function init() {

    if (!reviewsSectionElem || !reviewsSectionElem[0]) {

      return;

    }
    
    var reviewsUrl;
    if (fcplus) {
      reviewsUrl = '/reviews/homepage?fcplus=1';
    } else {
      reviewsUrl = '/reviews/homepage?fcplus=0';
    }

    $.get(reviewsUrl, onFetchReviews);

  }

  init();

  function onFetchReviews(res) {

    var i;

    reviewsData = res;

    if (fcplus && reviewsData.reviews.length <= 2) {
      carouselControlsElem.hide();
    }

    if (!lovinglyReviewsShowable(reviewsData.summary, reviewsData.reviews)) {

      return;

    }

    summaryElem.replaceWith(reviewsData.summary_html);
    carouselListElem.append(reviewsData.reviews_html.join('\n'));

    for (i = 0; i < Math.ceil(reviewsData.reviews.length) / pageSize; i++) {

      var elem = $('<button type="button" role="button">')
        .addClass(carouselButtonClass)
        // Must set attr, not just property, or we can't select by it
        .attr('data-page', i + 1)
        .attr('aria-label', 'carousel pagination')
        .on('click', setPage.bind(elem, i + 1));

      carouselControlsElem.append(elem);

    }

    // Find all reviews stars and initialize them
    if (reviewsSectionElem) {
      reviewsSectionElem.find('.reviews-section-rating-container')
        .rateYo();
    }

    // console.timeLog('homepage-reviews lyReadMore', lyReadMore);
    // lyReadMore('.reviews-carousel-review-content', {
    //   moreLinkText: '…Read More'
    // });

    setPage(1);

    reviewsSectionElem.show();

    window.lazyLoader.revalidate();

  }

  function lovinglyReviewsShowable(summary, reviews) {

    var total_count = fcplus ? 1 : 2;

    return summary &&
      reviews &&
      summary.total_count >= total_count &&
      summary.average_rating >= 3;

  }

  function setPage(page) {

    var activeButtonElem = carouselControlsElem.find('.' + carouselButtonActiveClass) ||
      carouselControlsElem.children()[0];

    var newActiveButtonElem = carouselControlsElem.find('[data-page="' + page + '"]');

    activeButtonElem.removeClass(carouselButtonActiveClass)
      .addClass(carouselButtonClass);

    newActiveButtonElem.removeClass(carouselButtonClass)
      .addClass(carouselButtonActiveClass);

    renderPage(page);

    window.lazyLoader.revalidate();

  }

  function renderPage(page) {

    var pages = getChunks(carouselListElem.children(), pageSize);

    carouselListElem.children().hide();

    if (pages[page - 1]) {

      pages[page - 1].show();

    }

  }

  function getChunks(arr, chunkSize) {

    return new Array(Math.ceil(arr.length / chunkSize))
      .fill()
      .map(function (_, i) {

        return arr.slice(i * chunkSize, i * chunkSize + chunkSize);

      });

  }

})(jQuery, lyReadMore);