'use strict';

window.$ = window.jQuery = $;

import '../vendor/jquery/jquery.countdown' //quiet
import '../vendor/star-rating'

import '../reveal-modal'
import '../homepage_core'
import '../flyout'

import '../single-product/single-product'
import '../single-product/ga-tracker.js'
import '../single-product/bing-tracker.js'
import './product-datepicker'
import '../chooseClosestAvailableDate'

import '../lazy-loading'
import '../coupon-modal'

import '../accessibility'
import '../sticky'
import './theme-layout-exclusive'
import '../countdown-timer'
import '../analytics/ab-test'